import React, { useRef } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
// import './ProxyBrowser.css'; // You can create a separate CSS file for styling

const ProxyBrowser = ({
    tabs,
    activeTab,
    setActiveTab,
    openNewTab,
    closeTab,
    handleChangeSearchTerm,
    loadWebsite,
    goBack,
}) => {
    const tabContainerRef = useRef(null);

    const handleTabClick = (tabId) => {
        setActiveTab(tabId);
    };

    return (
        <div className="proxy-browser">
            <AppBar position="static" className="app-bar" color="secondary">
                <Toolbar>
                    <div className="tab-container" ref={tabContainerRef}>
                        {tabs.map((tab) => (
                            <div
                                key={tab.id}
                                className={`tab ${tab.id === activeTab ? 'active' : ''}`}
                                onClick={() => handleTabClick(tab.id)}
                            >
                                <span>{`Tab ${tab.text}`}</span>
                                <IconButton
                                    onClick={() => closeTab(tab.id)}
                                    className="close-icon"
                                    size="small"
                                >
                                    <CloseIcon fontSize="small" />
                                </IconButton>
                            </div>
                        ))}
                    </div>
                    <IconButton
                        variant="contained"
                        color="primary"
                        onClick={openNewTab}
                        className="new-tab-button"
                    >
                        <AddIcon fontSize="large" className="add-icon" />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <div className="search-area">
                <InputBase
                    placeholder={tabs.length > 0 ? `Search in Tab ${activeTab}` : ''}
                    fullWidth
                    value={tabs.find((tab) => tab.id === activeTab)?.searchTerm || ''}
                    onChange={handleChangeSearchTerm}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            loadWebsite();
                        }
                    }}
                    className="search-input"
                />
                <Button
                    variant="contained"
                    color="primary"
                    onClick={loadWebsite}
                    className="load-button"
                >
                    Load
                </Button>
            </div>
            <div className="website">
                {activeTab && tabs.find((tab) => tab.id === activeTab)?.url && (
                    <iframe
                        title={`Tab ${activeTab}`}
                        id={`iframe-tab-${activeTab}`}
                        src={tabs.find((tab) => tab.id === activeTab)?.url || 'about:blank'}
                        width="100%"
                        style={{ height: "83vh" }}
                    />
                )}
            </div>

            <Button
                variant="contained"
                color="primary"
                onClick={goBack}
                className="back-button"
            >
                <ArrowBackIcon />
            </Button>
        </div>
    );
};

export default ProxyBrowser;
