import React, { useEffect, useContext } from "react";
import { titleContext } from "../../pages/auth/accounts/Feature";
import Apps from "../../components/Apps";


const DemoAnother = () => {

    const { setTitle } = useContext(titleContext);

    useEffect(() => {
        setTitle("Flamepass - Apps");
        document.title = "Flamepass - Apps";
    }, [setTitle]);

    return (
        <div>
            <Apps />
        </div>
        
    )
}

export default DemoAnother;