import React from 'react';

const LastPlayedGames = () => {
    // Placeholder data for last played games
    const lastPlayedGames = [
        {
            id: 1,
            name: 'Game 1',
            icon: 'https://via.placeholder.com/30x30', // Placeholder icon URL
        },
        {
            id: 2,
            name: 'Game 2',
            icon: 'https://via.placeholder.com/30x30', // Placeholder icon URL
        },
        {
            id: 3,
            name: 'Game 3',
            icon: 'https://via.placeholder.com/30x30', // Placeholder icon URL
        },
    ];

    return (
        <div className="bg-transparent p-4 rounded">
            <ul>
                {lastPlayedGames.map((game) => (
                    <li key={game.id} className="flex items-center space-x-2 mb-2">
                        <img
                            src={game.icon}
                            alt={game.name}
                            className="w-8 h-8 rounded-full"
                        />
                        <span className="text-white">{game.name}</span>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default LastPlayedGames;
