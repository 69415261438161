import React, { useState } from 'react';

const SearchBar = ({ data, onSearch }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearch = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    setSearchTerm(searchTerm);
    onSearch(searchTerm);
  };

  const getDomainFromUrl = (url) => {
    // Extract the domain from the URL
    const match = url.match(/:\/\/(www[0-9]?\.)?(.[^/:]+)/i);
    if (match != null && match.length > 2 && typeof match[2] === 'string' && match[2].length > 0) {
      return match[2];
    } else {
      return null;
    }
  };

  const handleItemClick = (url) => {
    window.location.href = url; // Navigate to the URL in the current window
  };

  const filteredResults = data.filter((item) =>
    item.title.toLowerCase().includes(searchTerm)
  ).slice(0, 5);

  return (
    <div className="relative">
      <div className="flex items-center">
        <div className="w-72 bg-gray-333 border border-gray-333 rounded-lg shadow-lg p-1">
          <input
            type="text"
            placeholder="Search apps..."
            value={searchTerm}
            onChange={handleSearch}
            className="w-full p-1 bg-gray-333 text-white focus:outline-none focus:ring-2 focus:ring-cd6636 rounded-lg"
          />
          {searchTerm && (
            <div className="z-20 absolute left-0 mt-1 w-72 bg-gray-333 border border-gray-333 rounded-lg shadow-lg">
              {filteredResults.map((result, index) => (
                <div
                  key={index}
                  className="flex items-center space-x-2 cursor-pointer hover:bg-gray-222 p-1 rounded"
                  onClick={() => handleItemClick(result.url)}
                >
                  <img
                    src={result.icon}
                    alt={result.title}
                    className="w-6 h-6"
                  />
                  <div>
                    <h3 className="text-sm font-medium text-white">{result.title}</h3>
                    <p className="text-xs text-gray-400">{getDomainFromUrl(result.url)}</p>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SearchBar;
