import React, { useEffect, useRef, useState } from "react";
import { useParams, Link, useHistory, useLocation } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import gamesData from "../Apps/data.json";
import RefreshIcon from "@mui/icons-material/Refresh";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import LaunchIcon from "@mui/icons-material/Launch"; 
import CloseIcon from "@mui/icons-material/Close"
const ConfirmDialog = ({ message, confirmLabel, abortLabel, onConfirm, onAbort }) => {
  return (
    <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-opacity-50 bg-black z-[1600]">
      <div className="bg-white rounded-lg shadow-lg p-6">
        <p className="text-red-600 text-lg font-semibold mb-4">{message}</p>
        <div className="flex justify-end">
          <button
            onClick={() => {
              onAbort();
            }}
            className="text-gray-600 hover:text-gray-800 font-semibold py-1 px-2 mr-4 border border-gray-300 rounded transition-colors"
          >
            {abortLabel}
          </button>
          <button
            onClick={() => {
              onConfirm();
            }}
            className="bg-gradient-to-r from-red-600 via-red-500 to-red-600 hover:from-red-700 hover:via-red-600 hover:to-red-700 text-white font-semibold py-1 px-3 rounded transition-colors"
          >
            {confirmLabel}
          </button>
        </div>
      </div>
    </div>
  );
};

const Games = () => {
  const mountedRef = useRef(true);
  const history = useHistory();
  const { id } = useParams();
  const location = useLocation();
  const [game, setGame] = useState(null);
  const [showIframe, setShowIframe] = useState(false);
  const [selectedLink, setSelectedLink] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false); // Track fullscreen state

  const handleRefreshIframe = () => {
    setSelectedLink((prevLink) => {
      // To refresh the iframe, change the key by adding a random string.
      const randomString = Math.random().toString(36).substring(7);
      return `${prevLink}?refresh=${randomString}`;
    });
  };

  const handleToggleFullscreen = () => {
    if (document.fullscreenElement) {
      // Exit fullscreen
      if (document.exitFullscreen) {
        document.exitFullscreen(); // Standard
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen(); // Firefox
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen(); // Webkit (Safari, Chrome)
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen(); // IE
      }
      setIsFullscreen(false);
    } else {
      // Enter fullscreen
      const element = document.documentElement;
      if (element.requestFullscreen) {
        element.requestFullscreen(); // Standard
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen(); // Firefox
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen(); // Webkit (Safari, Chrome)
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen(); // IE
      }
      setIsFullscreen(true);
    }
  };

  const openIframeInNewTab = () => {
    const newTab = window.open("about:blank", "_blank");

    if (newTab) {
      const iframe = document.createElement("iframe");
      iframe.style.border = "none";
      iframe.style.width = "100%";
      iframe.style.height = "100%";
      iframe.style.margin = "0";
      iframe.src = "https://flamepassclient.pages.dev/?uul=" + selectedLink;

      newTab.document.body.style.margin = "0";
      newTab.document.body.style.height = "100vh";
      newTab.document.body.appendChild(iframe);

      const myIframe = iframe.contentWindow;

      window.addEventListener("keydown", function (evt) {
        myIframe.myKeydownEventHandler(evt);
      });
    }
  };




  useEffect(() => {
    const selectedGame = gamesData.find((game) => game.url === `/games/${id}/`);

    if (selectedGame) {
      setGame(selectedGame);

      // Set the page title based on the loaded game title
      document.title = selectedGame.title + " | Flamepass";
    } else {
      // Handle the case when the game is not found
      // You can redirect to an error page or display a message
    }

    return () => {
      mountedRef.current = false;
      document.body.style.overflow = "visible"; // Reset body overflow when the component unmounts
    };
  }, [id]);

  useEffect(() => {
    const handleBodyOverflow = () => {
      if (showIframe || isFullscreen) {
        document.body.style.overflow = "hidden"; // Hide overflow when iframe is shown or in fullscreen
      } else {
        document.body.style.overflow = "visible"; // Show overflow when iframe is hidden and not in fullscreen
      }
    };

    handleBodyOverflow(); // Call it initially

    return () => {
      document.body.style.overflow = "visible"; // Reset body overflow when the component unmounts
    };
  }, [showIframe, isFullscreen]);

  const handleGoBack = () => {
    if (isIframe) {
      window.frameElement.parentElement.style.display = "none";
    } else {
      history.goBack();
    }
  };

  const handleOpenGame = (link) => {
    setShowIframe(false);

    const linkUrl = typeof link === "object" ? link.url : link;

    setSelectedLink(linkUrl);
    setShowIframe(true);
  };

  const handleCloseIframe = () => {
    if (showConfirmation) {
      setShowIframe(false);
      setSelectedLink(null);
      setShowConfirmation(false);
    } else {
      setShowConfirmation(true);
    }
  };

  const renderLinks = () => {
    if (game) {
      if (game.links && game.links.length > 0) {
        return (
          <div className="mt-4 text-center">
            {game.links.map((link, index) => (
              <button
                key={index}
                onClick={() => handleOpenGame(link.url)}
                className="mx-2 bg-orange-500 hover:bg-orange-600 text-white py-2 px-4 rounded-full text-center font-semibold dark:bg-gray-900 dark:hover:bg-gray-800 dark:text-gray-300"
              >
                {link.text}
              </button>
            ))};
          </div>
        );
      } else if (game.link) {
        return (
          <div className="mt-4 text-center">
            <button
              onClick={() => handleOpenGame(game.link)}
              className="mx-2 bg-orange-500 hover:bg-orange-600 text-white py-2 px-4 rounded-full text-center font-semibold dark:bg-gray-900 dark:hover:bg-gray-800 dark:text-gray-300"
            >
              {game.links && game.links[0] ? game.links[0].text : "Play Now"}
            </button>
          </div>
        );
      }
    }
    return null;
  };

  const renderIframeContent = () => {
    if (showIframe && selectedLink) {
      return (
        <>
          {showConfirmation && (
            <ConfirmDialog
              message="Terminate this session?"
              confirmLabel="Close session"
              abortLabel="Keep it open"
              onConfirm={() => {
                setShowConfirmation(false);
                handleCloseIframe();
              }}
              onAbort={() => setShowConfirmation(false)}
            />
          )}
          {/* Loader */}
          <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black z-[1500]">
            <div className="flex flex-col items-center">
              <div className="animate-spin mt-4 rounded-full h-12 w-12 border-t-4 border-b-4 border-orange-500"></div>
            </div>
          </div>
          {/* Loader */}
          <div className="fixed top-0 left-0 w-full h-full z-[1500]" onContextMenu={(e) => e.preventDefault()}>
            <div className="flex justify-between items-center">
              <div className="flex items-center"> {/* Adjusted this container */}
                {/* Your button components (unchanged) */}
                <Tippy content="Close Page" placement="bottom" className="bg-black">
                  <button
                    onClick={handleCloseIframe}
                    className="top-4 right-4 rounded-full bg-black w-8 h-8 flex items-center justify-center text-white text-2xl transition-opacity duration-300 opacity-90 hover:opacity-100"
                  >
                    <CloseIcon fontSize="medium" />
                  </button>
                </Tippy>
                {/* Fullscreen Icon */}
                <Tippy content={"Toggle Fullscreen"} placement="bottom" className="bg-black">
                  <button
                    onClick={handleToggleFullscreen}
                    className="top-4 left-12 rounded-full bg-black w-8 h-8 flex items-center justify-center text-white text-2xl transition-opacity duration-300 opacity-90 hover:opacity-100"
                  >
                    <FullscreenIcon fontSize="medium" />
                  </button>
                </Tippy>
                {/* New Tab Button with Launch Icon */}
                <Tippy content={"Open in New Tab"} placement="bottom" className="bg-black">
                  <button
                    onClick={openIframeInNewTab}
                    className="top-4 left-20 rounded-full bg-black w-8 h-8 flex items-center justify-center text-white text-2xl transition-opacity duration-300 opacity-90 hover:opacity-100"
                  >
                    <LaunchIcon fontSize="small" />
                  </button>
                </Tippy>
                {/* Refresh Icon */}
                <Tippy content="Refresh Page" placement="bottom" className="bg-black">
                  <button
                    onClick={handleRefreshIframe}
                    className="top-4 left-4 rounded-full bg-black w-8 h-8 flex items-center justify-center text-white text-2xl transition-opacity duration-300 opacity-90 hover:opacity-100"
                  >
                    <RefreshIcon fontSize="small" />
                  </button>
                </Tippy>
              </div>
              {/* Move this on the right side */}
              <div className="text-cd6636 text-lg font-semibold ml-4">
                {game ? game.title : ""}
              </div>
            </div>
            <iframe
              key={selectedLink}
              src={`https://flamepassclient.pages.dev/?uul=${selectedLink}`}
              className="w-full height-100-32"
              frameBorder="0"
            ></iframe>
          </div>

        </>
      );
    }
    return null;
  };

  const isIframe = new URLSearchParams(location.search).get("iframe") === "true";

  return (
    <div className="bg-gray-900 min-h-screen text-white">
      <nav className="bg-orange-500 p-4 dark:bg-gray-800">
        <div className="container mx-auto flex items-center justify-between">
          <button
            onClick={handleGoBack}
            className="text-white hover:text-gray-200"
          >
            {isIframe ? (
              <span className="text-2xl">&#10005;</span>
            ) : (
              <ArrowBackIcon />
            )}
          </button>
          {isIframe ? null : (
            <div className="flex items-center">
              <img
                src="/flamepass.png"
                alt="Flamepass Logo"
                className="h-8 mr-4"
              />
              <Link to="/continue" className="text-white hover:text-gray-200">
                Login
              </Link>
            </div>
          )}
        </div>
      </nav>
      <div className="container mx-auto py-8">
        {game ? (
          <div className="bg-gray-800 rounded-lg shadow-xl p-4">
            <img
              src={game.icon}
              alt={game.title}
              className="w-32 h-32 mx-auto rounded-full"
            />
            <h1 className="text-2xl font-bold text-center my-4">{game.title}</h1>
            <p className="text-gray-300 text-center">{game.description}</p>
            {renderLinks()}
          </div>
        ) : (
          <p className="text-red-500 text-center">Game not found</p>
        )}
        {renderIframeContent()}
      </div>
    </div>
  );
};

export default Games;
