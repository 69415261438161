import React, { useState, useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import Chart from 'chart.js/auto'; // Import Chart.js library
import './Dashboard.css';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

import LastPlayedGames from './lastPlayedGames';
import ProxyBrowser from './pages/proxyBrowser';
import TestPage from './pages/testPage';

const PAGES = {
  PROXY_BROWSER: 'proxyBrowser',
  TEST_PAGE: 'testPage',
};

const getPanelPositionsKey = (page) => `panelPositions_${page}`;

const Dashboard = ({ plan = 'free' }) => {
  const usageChartRef = useRef(null); // Define the usageChartRef

  const [tabs, setTabs] = useState(() => {
    const savedTabs = JSON.parse(localStorage.getItem('tabs'));
    return savedTabs || [];
  });

  const [activeTab, setActiveTab] = useState(null);
  const [activePage, setActivePage] = useState(null);
  const [panelPositions, setPanelPositions] = useState(() => {
    const savedPositions = JSON.parse(localStorage.getItem(getPanelPositionsKey(activePage)));
    return savedPositions || {};
  });

  const tabContainerRef = useRef(null);

  useEffect(() => {
    if (tabContainerRef.current) {
      tabContainerRef.current.scrollLeft = tabContainerRef.current.scrollWidth;
    }

    localStorage.setItem('tabs', JSON.stringify(tabs));
  }, [tabs]);

  useEffect(() => {
    const savedTabs = JSON.parse(localStorage.getItem('tabs'));
    if (savedTabs && savedTabs.length > 0) {
      setTabs(savedTabs);
      setActiveTab(savedTabs[savedTabs.length - 1].id);
    } else {
      openNewTab();
    }

    const key = getPanelPositionsKey(activePage);
    const savedPositions = JSON.parse(localStorage.getItem(key));
    if (savedPositions) {
      setPanelPositions(savedPositions);
    }
  }, [activePage]);

  const openNewTab = () => {
    const newTabNumber = tabs.length > 0 ? tabs[tabs.length - 1].text + 1 : 1;
    const newTab = { id: newTabNumber, text: newTabNumber, url: '', searchTerm: '' };
    setTabs([...tabs, newTab]);
    setActiveTab(newTab.id);
  };

  const closeTab = (tabId) => {
    const updatedTabs = tabs.filter((tab) => tab.id !== tabId);

    if (activeTab === tabId) {
      if (updatedTabs.length > 0) {
        setActiveTab(updatedTabs[updatedTabs.length - 1].id);
      } else {
        setActiveTab(null);
      }
    }

    setTabs(updatedTabs);
  };

  const handleChangeSearchTerm = (e) => {
    if (activeTab) {
      const updatedTabs = [...tabs];
      const tabIndex = tabs.findIndex((tab) => tab.id === activeTab);
      if (tabIndex !== -1) {
        updatedTabs[tabIndex].searchTerm = e.target.value;
        setTabs(updatedTabs);
      }
    }
  };

  const loadWebsite = () => {
    const activeTabIndex = tabs.findIndex((tab) => tab.id === activeTab);
    const activeTabData = tabs[activeTabIndex];

    const updatedTabs = [...tabs];
    updatedTabs[activeTabIndex].url = `https://flamepassclient.pages.dev/?uul=${encodeURIComponent(
      activeTabData.searchTerm || ''
    )}`;
    setTabs(updatedTabs);
  };

  const togglePage = (page) => {
    const key = getPanelPositionsKey(activePage);
    localStorage.setItem(key, JSON.stringify(panelPositions));
    setActivePage(page);
  };

  const goBack = () => {
    setActivePage(null);
  };

  const generateRandomData = () => {
    return Array.from({ length: 7 }, () => Math.floor(Math.random() * 100));
  };

  const [chartData, setChartData] = useState(generateRandomData());

  useEffect(() => {
    const ctx = document.getElementById('usageChart');

    // Destroy the previous chart if it exists
    if (ctx && usageChartRef.current) {
      usageChartRef.current.destroy();
    }

    if (ctx) {
      const usageChart = new Chart(ctx.getContext('2d'), {
        type: 'line',
        data: {
          labels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
          datasets: [
            {
              label: 'Data Transferred (MB)',
              data: chartData,
              borderColor: 'rgba(75, 192, 192, 1)',
              borderWidth: 2,
              fill: false,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
        },
      });

      usageChartRef.current = usageChart; // Store the chart instance in a ref
      // Update chart data every 5 seconds (for demonstration purposes)
      const interval = setInterval(() => {
        setChartData(generateRandomData());
        usageChart.data.datasets[0].data = chartData;
        usageChart.update();
      }, 5000);

      return () => {
        clearInterval(interval);
        usageChart.destroy(); // Cleanup: Destroy the chart when the component unmounts
      };
    }
  }, [chartData]);

  const showPage = (page) => {
    switch (page) {
      case PAGES.PROXY_BROWSER:
        return (
          <ProxyBrowser
            tabs={tabs}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            openNewTab={openNewTab}
            closeTab={closeTab}
            handleChangeSearchTerm={handleChangeSearchTerm}
            loadWebsite={loadWebsite}
            goBack={goBack}
          />
        );
      case PAGES.TEST_PAGE:
        return <TestPage back={goBack} />;
      default:
        return null;
    }
  };

  return (
    <div className="dashboard-container">
      {plan && (
        <div className={`cool-components w-full p-0 z-10${!activePage ? '' : ' hidden'}`}>
          <div className="container overflow-auto max-w-full">
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 mb-2">
              {/* Panel 1 */}
              <div className="bg-zinc-900 p-4 rounded-lg shadow-lg mb-2 text-white">
                <h6 className="text-uppercase mb-2 text-xl font-semibold">Welcome to Flamepass Proxy Dashboard</h6>
                <p className="text-gray-300">Your one-stop solution for secure and fast browsing.</p>
              </div>

              {/* Panel 2 */}
              {/* Add a button to open the Proxy Browser */}
              <div className="bg-zinc-900 p-4 rounded-lg shadow-lg mb-2 text-white">
                <h6 className="text-uppercase mb-2 text-xl font-semibold">Open Proxy Browser</h6>
                <Button variant="contained" color="primary" onClick={() => togglePage(PAGES.PROXY_BROWSER)}>
                  Open
                </Button>
              </div>

              {/* Panel 3 */}
              {/* Display the LastPlayedGames component */}
              <div className="bg-zinc-900 p-4 rounded-lg shadow-lg mb-2 text-white">
                <h6 className="text-uppercase mb-2 text-xl font-semibold">Last Played Games</h6>
                <LastPlayedGames />
              </div>

              {/* Panel 4 */}
              <div className="bg-zinc-900 p-4 rounded-lg shadow-lg mb-2 text-white">
                <h6 className="text-uppercase mb-2 text-xl font-semibold">Open Test Page</h6>
                <Button variant="contained" color="primary" onClick={() => togglePage(PAGES.TEST_PAGE)}>
                  Open
                </Button>
              </div>
            </div>
            <div className="grid grid-cols-1 gap-4 xl:grid-cols-2">
              {/* Panel 5 */}
              <div className="bg-zinc-900 p-4 rounded-lg shadow-lg text-white">
                <h6 className="text-uppercase mb-2 text-xl font-semibold">Engage with Flamepass Community</h6>
                <p className="text-gray-300">Join our forums and stay updated with the latest proxy trends.</p>
              </div>

              {/* Panel 6 */}
              {/* Add a button to open the Test Page */}
              <div className="bg-zinc-900 p-4 rounded-lg shadow-lg text-white">
                <h6 className="text-uppercase mb-2 text-xl font-semibold">Quick Access to Test Page</h6>
                <Button variant="contained" color="primary" onClick={() => togglePage(PAGES.TEST_PAGE)}>
                  Open
                </Button>
              </div>

              {/* Panel 7 */}
              <div className="bg-zinc-900 p-4 rounded-lg shadow-lg mb-2 text-white">
                <h6 className="text-uppercase mb-2 text-xl font-semibold">Proxy Usage Statistics</h6>
                <p className="text-gray-300">View your proxy usage statistics to optimize your browsing experience.</p>
                <canvas id="usageChart" width="400" height="200"></canvas>
              </div>

              {/* Panel 8 */}
              <div className="bg-zinc-900 p-4 rounded-lg shadow-lg mb-2 text-white">
                <h6 className="text-uppercase mb-2 text-xl font-semibold">Stay Secure and Anonymous</h6>
                <p className="text-gray-300">Flamepass ensures your online security and anonymity while browsing.</p>
                {/* You should define 'securityStatus' */}
                <div>
                  <h5>Security Status</h5>
                  {/* Replace 'securityStatus' with the actual state or value */}
                  <p>Encryption Level:1</p>
                  <p>IP Masking: 2</p>
                </div>
              </div>
            </div>
          </div>
          <footer className="bg-zinc-900 py-3">
            <div className="container">
              <div className="text-center">
                <p className="text-white font-bold mb-0">© 2020-2023 | Flamepass | <a href="/terms" className="text-blue-300 hover:text-blue-500">Terms of Service</a> | <a href="/privacy" className="text-blue-300 hover:text-blue-500">Privacy Policy</a></p>
              </div>
            </div>
          </footer>
        </div>
      )}
      {showPage(activePage)}
    </div>
  );
};

export default Dashboard;
