import React, { useState, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Loader from '../Loader'; // Replace with the correct path to your Loader component
import './Settings.css';
// import { AuthContext } from '../../components/FirebaseAuth';
// import { FirebaseAuth } from "../../components/FirebaseAuth/firebase";

const Settings = ({ plan = 'free' }) => {
  const [setting1, setSetting1] = useState(localStorage.getItem('setting1') === 'true');
  const [setting2, setSetting2] = useState(localStorage.getItem('setting2') || '');
  const [saving, setSaving] = useState(false);

  const handleSetting1Change = (event) => {
    const newValue = event.target.checked;
    setSetting1(newValue);
    localStorage.setItem('setting1', newValue);
  };

  const handleSetting2Change = (event) => {
    const newValue = event.target.value;
    setSetting2(newValue);
    localStorage.setItem('setting2', newValue);
  };

  const handleSaveSettings = async () => {
    // Show the loading icon
    setSaving(true);

    // Simulate saving to Firebase with a 2-second delay
    await saveSettingsToFirebaseWithDelay();

    // Hide the loading icon
    setSaving(false);
  };

  const saveSettingsToFirebaseWithDelay = async () => {
    // Simulate a 2-second delay
    await new Promise((resolve) => setTimeout(resolve, 2000));

    // Replace this with your actual Firebase database update logic
    // Example using Firebase Realtime Database:
    // const dbRef = firebase.database().ref('settings');
    // await dbRef.update({ setting1, setting2 });
  };

  useEffect(() => {
    setSetting1(localStorage.getItem('setting1') === 'true');
    setSetting2(localStorage.getItem('setting2') || '');
  }, []);

  return (
    <div className="dashboard-container">
      <AppBar position="static" className="app-bar" color="primary">
        <Toolbar>
          <Typography variant="h6" className="app-bar-title">
            Flamepass™ Settings
          </Typography>
        </Toolbar>
      </AppBar>
      <div className="settings-options">
        <div className="setting">
          <FormControlLabel
            control={<Switch checked={setting1} onChange={handleSetting1Change} />}
            label="Advanced Setting 1"
          />
        </div>
        <div className="setting">
          <TextField
            label="Advanced Setting 2"
            variant="outlined"
            value={setting2}
            onChange={handleSetting2Change}
            fullWidth
          />
        </div>
      </div>
      <div className="action-button">
        {!saving && (
          <Button
            variant="contained"
            color="primary"
            onClick={handleSaveSettings}
          >
            Save Settings
          </Button>
        )}
        {saving && (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Loader
              style={{
                position: 'relative',
                top: '0',
                left: '0',
                transform: 'none',
              }}
              size={22}
              text="Saving..."
            />
          </div>
        )}
      </div>
      <div className="website">
        {/* Render other advanced settings options here */}
      </div>
      {(plan === 'ember' || plan === 'inferno') && (
        <div className="cool-components">
          {plan === 'ember' && (
            <>
              {/* Ember-specific cool components $9.99/mo */}
            </>
          )}
          {plan === 'inferno' && (
            <>
              {/* Inferno-specific cool components $19.99/mo */}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default Settings;
