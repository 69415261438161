import React, { useState, useEffect, useRef } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import './Apps.css'; // Include your custom CSS as needed
import jsonData from './data.json';
import SearchBar from './searchBar';

const Apps = ({ plan = 'free' }) => {
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [iframeUrl, setIframeUrl] = useState('');

  const closeIframe = () => {
    setIframeUrl('');
    // Set display back to "none" when closing the iframe
    iframeContainerRef.current.style.display = 'none';
  };

  useEffect(() => {
    setData(jsonData);
  }, []);

  // Create a ref for the div element
  const iframeContainerRef = useRef(null);

  const handleSearch = (term) => {
    setSearchTerm(term);
  };

  const openIframe = (url) => {
    setIframeUrl(url);
    // Set display to "block" when opening the iframe
    iframeContainerRef.current.style.display = 'block';
  };

  const filteredData = data.filter((item) =>
    item.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="dashboard-container bg-gray-900 text-white">
      <AppBar position="static" className="app-bar bg-gray-800">
        <Toolbar>
          <div className="flex justify-center w-full">
            <Typography variant="h6" className="app-bar-title">
              Flamepass Apps
            </Typography>
            <div className="ml-auto"></div>
          </div>
          <SearchBar data={data} onSearch={handleSearch} />
        </Toolbar>
      </AppBar>

      {(plan === 'ember' || plan === 'inferno') && (
        <div className="cool-components">
          {plan === 'ember' && (
            <>
              {/* Ember-specific cool components $9.99/mo */}
            </>
          )}
          {plan === 'inferno' && (
            <>
              {/* Inferno-specific cool components $19.99/mo */}
            </>
          )}
        </div>
      )}
      <div className="grid grid-cols-1 gap-4 my-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 xl:grid-cols-7 2xl:grid-cols-8 xsm:grid-cols-2 overflow-auto">
        {filteredData.map((item, index) => (
          <div
            key={index}
            className="relative group cursor-pointer"
            onClick={() => openIframe(item.url)}
          >
            <div className="premium-card">
              <div
                className="card-image"
                style={{
                  backgroundImage: `url(${item.icon.replace('.webp', '.png')})`,
                }}
              ></div>
              <div className="card-overlay"></div>
              <div className="card-content">
                <h3 className="card-title" title={item.title}>
                  {item.title}
                </h3>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div
        ref={iframeContainerRef}
        className={`fixed top-16 left-14 appiframecontainer`}
        style={{display:"none"}}
      >
        {/* Loader */}
        <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center bg-black z-[1500]">
          <div className="flex flex-col items-center">
            <div className="animate-spin mt-4 rounded-full h-12 w-12 border-t-4 border-b-4 border-orange-500"></div>
          </div>
        </div>
        {/* Loader End */}
        <iframe
            title="App"
            src={`${iframeUrl}?iframe=true`}
          className="w-full h-full bg-white z-[1500] absolute"
          onClick={closeIframe}
        ></iframe>
      </div>
    </div>
  );
};

export default Apps;
