//! NEW SUBSCRIPTION PAGE

import React, {useState, useContext, useEffect, useRef } from "react";
import { CloudFunctions } from "../../../../components/FirebaseAuth/firebase";
import { BreadcrumbContext } from '../../../../components/Breadcrumb';
import { Redirect } from 'react-router-dom';
import { Container, Paper, Box, Alert } from "@mui/material";
import Loader from "../../../../components/Loader";

const NewAccount = () => {
    const title = 'Create New Account';
    const mountedRef = useRef(true);

    // const [accountName, setAccountName] = useState({
    //     hasError: false,
    //     error: null,
    //     value: null
    // });

    const [errorMessage, setErrorMessage] = useState(null);

    const [inSubmit, setInSubmit] = useState(false);

    const [redirect, setRedirect] = useState(null);
    const { setBreadcrumb } = useContext(BreadcrumbContext);

    useEffect(() => {
        setBreadcrumb([
            {
                to: "/",
                text: "Home",
                active: false
            },
            {
                to: null,
                text: title,
                active: true
            }
        ]);
    }, [setBreadcrumb, title]);

    useEffect(() => {
        setInSubmit(true);
        setErrorMessage(null);
        const createAccount = CloudFunctions.httpsCallable('createAccount');
        createAccount({
            accountName: /*accountName.value*/ "subscription1", //only 1 subscription
        }).then(response => {
            if (!mountedRef.current) return null;
            const accountId = response.data.accountId;
            setRedirect('/');
        }).catch(err => {
            if (!mountedRef.current) return null;
            setErrorMessage(err.message);
            setInSubmit(false);
        });

        return () => {
            mountedRef.current = false;
        };
    }, []);


    return (
    <Container>
        <Paper>
        <Box p={2}>
            {redirect === null && 
            <>
                {errorMessage !== null && 
                <Alert severity="error" dismissible={true} onDismiss={() => setErrorMessage(null)}>{errorMessage}</Alert>
                }
                <Loader/>
                {/* Comment out the form-related code
                <div className="card-body">
                <Form submitBtnText="Create Subscription" 
                    handleSubmit={e => {
                    e.preventDefault();
                    setInSubmit(true);
                    setErrorMessage(null);
                    const createAccount = CloudFunctions.httpsCallable('createAccount');
                    createAccount({
                        accountName: "subscription1", //only 1 subscription
                    }).then(response => {
                        if (!mountedRef.current) return null;
                        const accountId = response.data.accountId;
                        setRedirect('/account/'+accountId+'/billing/plan');
                    }).catch(err => {
                        if (!mountedRef.current) return null;
                        setErrorMessage(err.message);
                        setInSubmit(false);
                    })
                    }}
                    disabled={accountName.hasError || accountName.value===null || inSubmit} //only 1 subscription
                    inSubmit={inSubmit}
                    enableDefaultButtons={true} //*submit button
                >
                    <h2 style={{margin:"0px"}}>Create a Subscription</h2>
                    <Input label="Account Name" type="text" name="account-name" maxLen={100} required={true} changeHandler={setAccountName} fullWidth variant="outlined" />
                </Form>
                </div>
                */}
            </>
            }
            {redirect !== null &&
            <Redirect to={redirect}></Redirect>
            }
        </Box>
        </Paper>
    </Container>
    )
}

export default NewAccount;