import React from "react";
import FirebaseUI from '../../../components/FirebaseUI';
import Logo from "../../../components/Logo";
import "./firebaseui.css"; // Import your custom FirebaseUI CSS

const SignIn = () => {
    return (
        <section className="text-white">
            <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
                <a href="#" className="flex items-center mb-6 text-2xl font-semibold text-white">
                    <Logo justimg={false}/>
                </a>
                <div style={{ backgroundColor: "#222" }} className="w-full rounded-lg shadow dark:border-gray-700 md:mt-0 sm:max-w-md xl:p-0">
                    <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                        <h1 className="text-xl font-bold leading-tight tracking-tight md:text-2xl">
                            Sign In
                        </h1>
                        <div className="space-y-4 md:space-y-6">
                            {/* Your FirebaseUI component can go here */}
                            <FirebaseUI />
                        </div>
                    </div>
                </div>
                <p className="mt-4 text-gray-500 text-center">&copy; {new Date().getFullYear()} Flamepass</p>
            </div>
        </section>
    );
}

export default SignIn;
