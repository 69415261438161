import React from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Button from '@mui/material/Button';

const TestPage = ({ back }) => (
    <div className="test-button-page">
        <h2>Test Page</h2>
        <Button
            variant="contained"
            color="primary"
            onClick={back} // Make sure `back` is a function
            className="back-button"
        >
            <ArrowBackIcon />
        </Button>
    </div>
);

export default TestPage;
