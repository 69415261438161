import React from "react";

const Logo = ({size, justimg = true}) => {
    const imgSize = size || '36px';
    return (justimg ?
        <>
        
            <img src="/logo.png" alt="logo" style={{height: imgSize, width: imgSize, display:"inherit"}}></img>
        </>
    :
        <>
            <img src="/flamepass.png" alt="logo with text" style={{ height: 53, width: 211.75 }}></img>
        </>
    )
}

export default Logo;