import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../components/FirebaseAuth';
import { titleContext } from "../../pages/auth/accounts/Feature";
import { FirebaseAuth } from "../../components/FirebaseAuth/firebase";
import { Container, Typography } from '@mui/material'; // Add these imports
import Dashboard from "../../components/Dashboard";

const Demo = () => {
  const Firestore = FirebaseAuth.firestore();
  const { setTitle } = useContext(titleContext);

  // Access the user object from the Firebase AuthContext
  const { userData } = useContext(AuthContext);

  let planName = 'Unknown Plan';

  // Define a state variable to store the subscription tier
  const [subscriptionTier, setSubscriptionTier] = useState('');
  const [subscriptionName, setSubscriptionName] = useState('');
  useEffect(() => {
    setTitle('Home | Flamepass');
    document.title = 'Home | Flamepass';

    if (userData) {
      // Reference to the Firestore collection and document
      const userDocRef = Firestore.collection('accounts').doc(userData.currentAccount.id); // Assuming user.currentAccount.id is the correct document ID

      // Get the data from the document
      userDocRef.get()
        .then((doc) => {
          if (doc.exists) {
            const planId = userData.currentAccount.planId;
            if (planId === 'free') {
              planName = 'Free Plan';
            } else if (planId === 'ember') {
              planName = 'Ember Plan';
            } else if (planId === 'inferno') {
              planName = 'Inferno Plan';
            }
            setSubscriptionTier(planId);
            setSubscriptionName(planName);
          } else {
            console.log('No such document!');
          }
        })
        .catch((error) => {
          console.error('Error getting document:', error);
        });
    }
  }, [setTitle, userData]);

  return (
  <Container maxWidth="lg" sx={{padding:"0!important", maxWidth:"none!important",paddingRight:"0!important",paddingRight:"0!important"}}>
    {/* <Typography variant="h3" align="center" gutterBottom>
      {subscriptionName}
    </Typography> */}
    <Dashboard plan={subscriptionTier}/> {/* Load Dashboard*/}
    
  </Container>
);

};

export default Demo;
