import { createTheme } from '@mui/material/styles';

const darkTheme = createTheme({
  palette: {
    type: 'dark', // Enable dark mode
    primary: {
      main: '#cd6636', // Example primary color for dark mode
    },
    secondary: {
      main: 'rgba(0,0,0,0.5)', // Complementary orange color
    },
    background: {
      paper: '#222', // Dark gray background for paper elements
      default: '#333', // Even darker background for the entire app
    },
    text: {
      primary: '#fff', // White text color
      secondary: '#ccc', // Lighter gray text color for secondary text
    },
  },
  components: {
    MuiIconButton: {
      styleOverrides: {
        root: {
          // Change the color of the icons in IconButton components
          color: '#f0f0f0', // Slightly grayish tone
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          // Change the color of the icons in ListItemIcon components
          color: '#f0f0f0', // Slightly grayish tone
        },
      },
    },
    MuiButtonBase: {
      root: {
        '&.css-78trlr-MuiButtonBase-root-MuiIconButton-root': {
          // Change the color of elements with the specified class
          color: '#f0f0f0', // Slightly grayish tone
        },
      },
    },
  },
});

export default darkTheme;
