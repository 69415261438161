import React from "react";
import { Box, Container } from "@mui/material"

const PublicTemplate = ({ children }) => {
	return (
		<Box sx={{background: "transparent", color: "white"}}>
			<Container sx={{maxWidth:"none!important"}}>
				<Box component="span" textAlign="center">
					{children}
				</Box>
			</Container>
		</Box>
	)
}

export default PublicTemplate;